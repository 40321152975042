import { Link, graphql } from "gatsby";
import * as React from "react";
import { Block, Button } from "react-bulma-components";
import styled from "styled-components";

import { ContainerFullWidth, HomeBackgroundImage, Layout, ShowsContainer, Subtitle, VideosContainer } from "../components/index";

export const data = graphql`
    query {
        videos: videosHomeJson {
            featured {
                videos {
                    ...videoData
                }
            }
            videos {
                ...videoData
            }
        }
    }
`;

const VideosContainerAnchor = styled.a`
    display: block;
    position: relative;
    top: -${props => props.theme.space.navbar};
    visibility: hidden;
`;

const IndexPage = ({ data: { videos }, ...props }) => {
    return (
        <Layout title="Home" {...props}>
            <HomeBackgroundImage />

            <VideosContainerAnchor id="videos" />
            <VideosContainer videosList={videos} />

            <Block textAlign="center">
                <Button renderAs={Link} rounded={true} to="/videos/">All Videos</Button>
            </Block>

            <ContainerFullWidth pt={5}>
                <Subtitle>Shows</Subtitle>

                <ShowsContainer isCompact={true} />

                <Block textAlign="center">
                    <Button renderAs={Link} rounded={true} textAlign="center" to="/shows/">All Shows</Button>
                </Block>
            </ContainerFullWidth>
        </Layout>
    );
};

export default IndexPage;